import React, { useState, Fragment } from "react";
// Material-UI imports
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  Typography,
  Box,
  IconButton
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
// Custom imports
import ForgotPasswordForm from "./ForgotPasswordForm";

export default function ForgotPasswordModal() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <Fragment>
      <Button
        variant="text"
        color="primary"
        onClick={handleClickOpen}
        className={classes.utilityButton}
      >
        Forgot password?
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="forgot-password-dialog-title"
        aria-describedby="forgot-password-dialog-content"
      >
        <DialogTitle id="forgot-password-dialog-title">
          <Typography component="h2" variant="h5">
            Forgot password?
          </Typography>
          <IconButton className={classes.closeIcon} onClick={handleClose} size="large">
            <span className={classes.topXLine}></span>
            <span className={classes.bottomXLine}></span>
          </IconButton>
        </DialogTitle>
        <DialogContent id="forgot-password-dialog-content">
          <Box py={2}>
            <ForgotPasswordForm />
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  utilityButton: {
    textTransform: "none"
  },
  closeIcon: {
    cursor: "pointer",
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(4),
    width: "32px",
    height: "32px"
  },
  topXLine: {
    width: "20px",
    height: "2px",
    background: "#666",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%) rotate(45deg)"
  },
  bottomXLine: {
    width: "20px",
    height: "2px",
    background: "#666",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%) rotate(-45deg)"
  }
}));
