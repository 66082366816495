import React, { Fragment, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

// Material-UI imports
import { Paper, Typography, TextField, Box, Button, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
// Custom imports
import { logIn, userIsAuthenticated, logOut, fetchApi } from "@globalholdings/ui-common-functions";
import { ReactComponent as Logo } from "../../svg/GHLogo.svg";
import ForgotPasswordModal from "../ForgotPasswordModal";
// import ForgotUsernameModal from "../ForgotUsernameModal";
import { ReactComponent as AdLoginLogo } from "../../svg/adLogin.svg";

export default function Login() {
  const history = useHistory();
  const classes = useStyles();
  const [values, setValues] = useState({
    username: "",
    password: "",
  });
  const [hasErrors, setHasErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAdUser, setIsAdUser] = useState(false);
  const timer = useRef();

  const urlParams = new URLSearchParams(window.location.search);
  const refererr = urlParams.get("referrer");
  const path = urlParams.get("referedPath");

  const handleInputChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const tokenEndpoint = `${window.CONFIG.AUTH_API_URL}/auth/token`;
    const credentials = {
      username: values.username.toLowerCase(),
      password: values.password,
      isAdUser: isAdUser,
      AppName: process.env.REACT_APP_APPNAME,
      AppSecret: process.env.REACT_APP_APPSECRET,
      Referrer: refererr,
    };
    fetchApi("POST", tokenEndpoint, credentials, false)
      .then((response) => {
        if (response.status === 200) {
          const lastKnownReferrer = localStorage.getItem("lastKnownReferrer");
          timer.current = setTimeout(() => {
            setIsLoading(false);
            logIn(response);
            userIsAuthenticated();
            if (response.data.isTempPassword) {
              window.location.assign("/update-temp-password");
            } else {
              if (refererr === "UI.Boilerplate") {
                localStorage.setItem("lastKnownReferrer", refererr);
                window.location.assign(
                  `${window.CONFIG.BOILERPLATE_URL}/?accesstoken=${response.data.accessToken}&refreshtoken=${response.data.refreshToken}&username=${response.data.username}&isAdUser=${response.data.isAdUser}`
                );
              }
              if (refererr === "UI.CompanyConfiguration") {
                localStorage.setItem("lastKnownReferrer", refererr);
                window.location.assign(
                  `${window.CONFIG.COMPANYCONFIG_URL}/?accesstoken=${response.data.accessToken}&refreshtoken=${response.data.refreshToken}&username=${response.data.username}`
                );
              }

              if (refererr === "UI.PhonePay") {
                localStorage.setItem("lastKnownReferrer", refererr);
                window.location.assign(
                  `${window.CONFIG.PHONEPAY_URL}/?accesstoken=${response.data.accessToken}&refreshtoken=${response.data.refreshToken}&username=${response.data.username}&isAdUser=${response.data.isAdUser}`
                );
              }
              if (refererr === "UI.StatementLinker") {
                localStorage.setItem("lastKnownReferrer", refererr);
                window.location.assign(
                  `${window.CONFIG.STATEMENTLINKER_URL}/?accesstoken=${response.data.accessToken}&refreshtoken=${response.data.refreshToken}&username=${response.data.username}&isAdUser=${response.data.isAdUser}`
                );
              }
              if (refererr === "UI.DirectPay") {
                localStorage.setItem("lastKnownReferrer", refererr);
                window.location.assign(
                  `${window.CONFIG.DIRECTPAY_URL}/?accesstoken=${response.data.accessToken}&refreshtoken=${response.data.refreshToken}&username=${response.data.username}&isAdUser=${response.data.isAdUser}`
                );
              }
              if (refererr === "UI.Attrition") {
                localStorage.setItem("lastKnownReferrer", refererr);
                window.location.assign(
                  `${window.CONFIG.ATTRITION_URL}/?accesstoken=${response.data.accessToken}&refreshtoken=${response.data.refreshToken}&username=${response.data.username}&isAdUser=${response.data.isAdUser}`
                );
              }
              if (refererr === "UI.ClientDebt") {
                if (path != null && path != "") {
                  window.location.assign(
                    `${window.CONFIG.CLIENT_DEBT_URL}${path}/?accesstoken=${response.data.accessToken}&refreshtoken=${response.data.refreshToken}&username=${response.data.username}&isAdUser=${response.data.isAdUser}`
                  );
                } else {
                  window.location.assign(
                    `${window.CONFIG.CLIENT_DEBT_URL}/?accesstoken=${response.data.accessToken}&refreshtoken=${response.data.refreshToken}&username=${response.data.username}&isAdUser=${response.data.isAdUser}`
                  );
                }
              }
              if (refererr === "UI.Reporting") {
                localStorage.setItem("lastKnownReferrer", refererr);
                window.location.assign(
                  `${window.CONFIG.REPORTING_URL}/?accesstoken=${response.data.accessToken}&refreshtoken=${response.data.refreshToken}&username=${response.data.username}&isAdUser=${response.data.isAdUser}`
                );
              }
              if (refererr === "UI.USERADMIN") {
                localStorage.setItem("lastKnownReferrer", refererr);
                window.location.assign(
                  `${window.CONFIG.USERADMIN_URL}/?accesstoken=${response.data.accessToken}&refreshtoken=${response.data.refreshToken}&username=${response.data.username}&isAdUser=${response.data.isAdUser}`
                );
              }
              if (refererr === "UI.CommunicationPreferences") {
                localStorage.setItem("lastKnownReferrer", refererr);
                window.location.assign(
                  `${window.CONFIG.COMMUNICATIONPREFERENCES_URL}/?accesstoken=${response.data.accessToken}&refreshtoken=${response.data.refreshToken}&username=${response.data.username}&isAdUser=${response.data.isAdUser}`
                );
              }
              if (!refererr) {
                history.push("/home");
              }
            }
          }, 500);
        } else {
          setHasErrors(true);
          setErrorMessage(response.status);
          setIsLoading(false);
          throw new Error(`Error Code ${response.status}`);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setHasErrors(true);
        if (error.response) {
          if (error.response.data.error === "user_locked_out") {
            setErrorMessage(
              "Too many failed login attempts, your account has been locked. Please try again later."
            );
          } else {
            setErrorMessage(error.response.data.error_description);
          }
        } else {
          setErrorMessage("Failed to connect to the server. Please try again later.");
        }
      });
  };

  const handleUserToggle = () => {
    console.log("handleUSerToggle fired");
    setIsAdUser(!isAdUser);
    localStorage.setItem("loginTypeAd", !isAdUser);
  };

  useEffect(() => {
    if (localStorage.getItem("loginTypeAd")) {
      localStorage.getItem("loginTypeAd") === "false" ? setIsAdUser(false) : setIsAdUser(true);
    }
    if (userIsAuthenticated()) {
      logOut("");
    }
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  return (
    <Box spacing={0} className={classes.fullHeight}>
      <Paper className={classes.loginContainer}>
        <Box>
          <a href="/">
            <Logo className={classes.logo} alt="Global" />
          </a>
          <Typography component="h1" align="center" gutterBottom>
            Log into your Global Holdings application
          </Typography>
        </Box>
        <Box pr={2} className={classes.loginForm}>
          <form id="login" onSubmit={handleSubmit} autoComplete="off">
            <TextField
              id="username"
              name="username"
              label="Username"
              value={values.username}
              onChange={handleInputChange("username")}
              margin="dense"
              variant="outlined"
              error={hasErrors}
              fullWidth
              autoFocus
              required
              InputProps={{ inputProps: { autoComplete: "off" } }}
            />
            <TextField
              type="password"
              id="password"
              name="password"
              label="Password"
              value={values.password}
              onChange={handleInputChange("password")}
              margin="dense"
              variant="outlined"
              error={hasErrors}
              fullWidth
              required
              InputProps={{
                inputProps: { autoComplete: "off", maxLength: 64 },
              }}
            />
            {hasErrors && (
              <div>
                <small className="error">{errorMessage}</small>
              </div>
            )}
            <Box mt={2} className={classes.submitContainer}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}
                sx={{ borderRadius: "50px" }}
              >
                Log in
              </Button>
              {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Box>
          </form>
        </Box>
        {isAdUser ? (
          <Fragment>
            <Box align="center" pt={2} mt={4} className={classes.utilityMenu}>
              <Button
                color="primary"
                type="submit"
                onClick={() => handleUserToggle()}
                sx={{ fontWeight: "bold", textTransform: "none" }}
              >
                Log in with your non-Global account
              </Button>
            </Box>
          </Fragment>
        ) : (
          <Fragment>
            <Box align="center" pt={2} mt={4} className={classes.utilityMenu}>
              <ForgotPasswordModal />
              <Button
                color="primary"
                type="submit"
                onClick={() => handleUserToggle()}
                sx={{ fontWeight: "bold", textTransform: "none" }}
              >
                <AdLoginLogo alt="Global" className={classes.brandMark} />
                Log in with your Global account
              </Button>
            </Box>
          </Fragment>
        )}
      </Paper>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  logo: {
    display: "block",
    width: "80%",
    maxWidth: 180,
    padding: theme.spacing(0, 0, 4),
    margin: "0 auto",
  },
  title: {
    padding: "20px 0",
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
  loginForm: {
    "@media (min-width: 840px)": {
      height: "100%",
      width: "70%",
      margin: "0 auto",
    },
  },
  fullHeight: {
    height: "100vh",
    background: "#eee",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loginContainer: {
    maxWidth: "396px",
    padding: 32,
    background: "#fff",
  },
  submitContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  utilityMenu: {
    borderTop: "1px solid #ddd",
    display: "flex",
    flexDirection: "column",
  },
  brandMark: {
    marginRight: theme.spacing(1),
    width: "12px",
    height: "12px",
    padding: theme.spacing(0.5),
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
  },
}));
