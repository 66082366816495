import React, { Fragment, useState } from "react";
// Material-UI imports
import { Box, TextField, Button, Typography } from "@mui/material/";
// Custom imports
import {
  fetchApi,
  characterCountEqualsTo
} from "@globalholdings/ui-common-functions";

export default function SetUserPassForm() {
  const [username, setUsername] = useState("");
  const [confirmation, setConfirmation] = useState({
    text: "",
    classname: "",
    ariaStatus: "status"
  });
  const [helperText, setHelperText] = useState("");
  const [hasErrors, setHasErrors] = useState(false);

  const isButtonEnabled = () => {
    if (username.length > 0) {
      if (Object.values(hasErrors).every(item => item === false)) {
        return true;
      }
    } else {
      return false;
    }
  };

  const onSubmit = event => {
    event.preventDefault();
    const api = `${window.CONFIG.AUTH_API_URL}/auth/forgotpassword`;
    const data = {
      username: username,
      AppName: process.env.REACT_APP_APPNAME,
      AppSecret: process.env.REACT_APP_APPSECRET
    };
    fetchApi("POST", api, JSON.stringify(data), false)
      .then(response => {
        if (response.status === 200) {
          if (response.data.hasErrors === true) {
            setConfirmation({
              text: `${response.data.errors[0].message}. Please contact us for support or try again in 10 seconds.`,
              classname: "error",
              ariaStatus: "alert"
            });
          } else if (response.data.hasWarnings === true) {
            setConfirmation({
              text: `${response.data.errors[0].message}. Please contact us for support or try again in 10 seconds`,
              classname: "error",
              ariaStatus: "alert"
            });
          } else {
            setConfirmation({
              text: "An email containing your temporary password was sent.",
              classname: "success",
              ariaStatus: "alert"
            });
          }
        } else {
          throw new Error(`Error Code ${response.status}`);
        }
      })
      .catch(error => {
        setHasErrors(true);
        if (error.response) {
          setConfirmation({
            text: error.response.data.error_description,
            classname: "error",
            ariaStatus: "alert"
          });
        } else {
          setConfirmation({
            text: `Something went wrong. Please contact us for support:`,
            classname: "error",
            ariaStatus: "alert"
          });
        }
      });
  };

  const onBlur = prop => event => {
    const value = event.target.value;
    if (prop === "username") {
      if (characterCountEqualsTo(value, 0)) {
        setHelperText("Username is required.");
        setHasErrors(true);
      } else {
        setHelperText("");
        setHasErrors(false);
      }
    }
  };

  const handleUsernameChange = event => {
    setUsername(event.target.value);
  };

  return (
    <Fragment>
      <form onSubmit={onSubmit}>
        <Box px={2}>
          <TextField
            id="username"
            name="username"
            label="Username"
            placeholder="Enter your username"
            variant="outlined"
            margin="normal"
            value={username}
            helperText={helperText}
            error={hasErrors}
            onBlur={onBlur("username")}
            onChange={handleUsernameChange}
            fullWidth={true}
            InputProps={{ inputProps: { autoComplete: "off", maxLength: 20 } }}
            InputLabelProps={{ shrink: true, required: false }}
            autoFocus
            required={true}
          />
        </Box>
        <Box m={2} tabIndex="0">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isButtonEnabled()}
            sx={{ borderRadius: "50px" }}
          >
            Send me reset instructions
          </Button>
        </Box>
      </form>
      <Box px={2}>
        <Typography
          className={confirmation.classname}
          role={confirmation.ariaStatus}
        >
          {confirmation.text}
          {confirmation.phone && <div>{confirmation.phone}</div>}
        </Typography>
      </Box>
    </Fragment>
  );
}
