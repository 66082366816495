import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import { render } from "react-dom";
import { createTheme, adaptV4Theme } from "@mui/material/styles";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { unregister } from "./serviceWorker";
import App from "./containers/App";
import "./styles.css";
require("typeface-poppins");

unregister();

const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: "#1373e6",
      light: "#fff"
    }
  },
  typography: {
    fontFamily: [
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(",")
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },

  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        padding: "6px 32px",
        borderRadius: "18px"
      },
      text: {
        textTransform: "none"
      }
    },
    MuiAppBar: {
      root: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 16px",
        height: 60
      },
      colorDefault: {
        backgroundColor: "#ffffff"
      }
    },
    MuiDialogTitle: {
      root: {
        padding: "16px 40px",
        borderBottom: "1px solid #eee"
      }
    },
    MuiTab: {
      root: {
        flexShrink: 1,
        textTransform: "none",
        "@media (min-width: 600px)": {
          minWidth: "80px"
        }
      }
    },
    PrivateTabIndicator: {
      root: {
        bottom: "10px",
        height: "3px"
      }
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 8,
        marginBottom: 8
      }
    },
    MuiTypography: {
      h1: {
        fontSize: "1.15rem",
        fontWeight: "600",
        color: "#888"
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: "12px 14px"
      }
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 12px) scale(1)"
      }
    }
  }
}));
render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById("root")
);
