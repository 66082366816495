import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// Custom imports
import { Loading } from "@globalholdings/ui-common-components";
import Layout from "../../containers/Layout";
import PrivateRoute from "../../components/PrivateRoute";
import { useState } from "react";
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';


import makeStyles from '@mui/styles/makeStyles';


const Login = lazy(() => import("../../components/Login"));
const Home = lazy(() => import("../../components/Home"));

const ChangePasswordForm = lazy(() =>
  import("../../components/ChangePasswordForm")
);

const ChangePasswordFromLink = lazy(() =>
  import("../../containers/ChangePasswordFromLink")
);


const theme = createTheme();

const useStyles = makeStyles((theme) => {
    root: {
        // some CSS that access to theme
    }
});

export default function App() {
    const classes = useStyles(); 

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
    <Suspense fallback={<Loading isLoading={true} />}>
      <Router>
        <Layout>
          <Switch>
            <PrivateRoute
              exact
              path="/update-temp-password"
              component={ChangePasswordForm}
            />
            <Route exact path="/" component={Login} />
            <PrivateRoute
            exact
            path="/home"
            component={Home}
          />
            <Route exact path="/Changepasswordlink" component={ChangePasswordFromLink} />
          </Switch>
        </Layout>
      </Router>
            </Suspense>
        </ThemeProvider>
      </StyledEngineProvider>
    );
}
