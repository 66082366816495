import React, { Fragment, useState, useEffect } from "react";
// Custom imports
import { userIsAuthenticated } from "@globalholdings/ui-common-functions";

export default function Layout(props) {
  if (userIsAuthenticated() && window.location.pathname !== "/") {
    return (
      <Fragment>
        {props.children}
      </Fragment>
    );
  } else {
    return <Fragment>{props.children}</Fragment>;
  }
}
